<template>
    <div class="supplierDetails">
        <div class="module header bs_b pl10 pb10 pt10 pr24 flex f_jc_b f_ai_c">
            <div class="flex f_ai_c">
                <img class="logo mr30" src="../../assets/images/common/mnLogo.png" alt="">
                <div>
                    <div class="fs20" style="color: #1991FC;">{{form.companyName}}</div>
                    <div class="fs14 mt8" style="color: #716E6E;">
                        <span class="mr25">供应商账户：{{form.userName}} </span>
                        <span class="mr25">供应商编码：{{form.supplyCode}} </span>
                        <span>供应商名称：{{form.userCompanyName}} </span>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="fs18 ta_r" style="color: #1991FC;">{{form.status == 1? '审批中': form.status == 2? '已拒绝': form.status == 3? '已通过': ''}}</div>
                <!-- <div class="fs14 m4" style="color: #716E6E;">审核次数：4</div> -->
            </div>
        </div>
        <div class="module header bs_b pl24 pb20 pt20 pr24">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/titleIcon.png" alt="">
                <div class="title-text">联系人信息</div>
            </div>
            <div class="field-box flex">
                <div>
                    <span class="field-name">联系人电话：</span>
                    <span class="field-main">{{form.mobile}}</span>
                </div>
                <div>
                    <span class="field-name">联系人邮箱：</span>
                    <span class="field-main">{{form.email}}</span>
                </div>
            </div>
        </div>

        <div class="module bs_b pl24 pb20 pt20 pr24">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/titleIcon.png" alt="">
                <div class="title-text">企业信息</div>
            </div>
            <div class="field-box flex flex_wrap">
                <div class="field-item">
                    <span class="field-name">企业名称：</span>
                    <span class="field-main">{{form.companyName}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">统一社会信用代码：</span>
                    <span class="field-main">{{form.socialUnifiedIdentityCreditCode}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">执照类型：</span>
                    <span class="field-main">多证合一营业执照（统一社会信用代码）</span>
                </div>
                <div class="field-item">
                    <span class="field-name">营业执照所在地：</span>
                    <span class="field-main">{{form.licenseLocationName}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">住所：</span>
                    <span class="field-main">{{form.residence}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">成立日期：</span>
                    <span class="field-main">{{form.companyCreateDate}}</span>
                </div>
                <div  class="field-item">
                    <span class="field-name">营业期限：</span>
                    <span v-if="form.businessTermStatus != 1" class="field-main">{{form.businessBeginTerm}} 至 {{form.businessEndTerm}}</span>
                    <span v-else class="field-main">长期有效</span>
                </div>
                <div  class="field-item">
                    <span class="field-name">注册资本：</span>
                    <span class="field-main">{{form.registeredCapital}}万元</span>
                </div>
                <div  class="field-item flex">
                    <div class="field-name">经营范围：</div>
                    <div class="field-main flex1">{{form.natureBusiness}}</div>
                </div>
                <div  class="field-item flex">
                    <span class="field-name">营业执照：</span>
                    <img class="field-img" @click="dialogImageUrlClick(form.buslicenseFileBOlist[0].filePath)" :src="form.buslicenseFileBOlist[0].filePath" alt="">
                </div>
                <div class="field-line mb23"></div>
                <div  class="field-item flex">
                    <div class="field-name">法定代表人证件类型：</div>
                    <div class="field-main flex1">{{form.credentialsType == 1? '中国大陆身份证' : ''}}</div>
                </div>
                <div class="field-item">
                    <span class="field-name">证件所有人姓名：</span>
                    <span class="field-main">{{form.credentialsName}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">证件号码：</span>
                    <span class="field-main">{{form.credentialsNumber}}</span>
                </div>
                <div class="field-item">
                    <span class="field-name">证件有效期：</span>
                    <span v-if="form.credentialsValidityStatus != 1" class="field-main">{{form.credentialsValidityBeginDate}} 至 {{form.credentialsValidityEndDate}}</span>
                    <span v-else class="field-main">长期有效</span>
                </div>
                <div class="field-item flex" style="vertical-align: top;">
                    <span class="field-name">法定代表人证件图片：</span>
                    <img class="field-img mr10"  @click="dialogImageUrlClick(form.idCardFileBOlist[0].filePath)" :src="form.idCardFileBOlist[0].filePath" alt="">
                    <img class="field-img" @click="dialogImageUrlClick(form.idCardFileBOlist[1].filePath)" :src="form.idCardFileBOlist[1].filePath" alt="">
                </div>

            </div>

        </div>

        <div class="module bs_b pl24 pb20 pt20 pr24">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/titleIcon.png" alt="">
                <div class="title-text">类目信息</div>
            </div>
            <div class="field-box flex">
                <div>
                    <span class="field-name">主营类目：</span>
                    <span class="field-main" style="margin-right: 0;">{{form.commodCategoryName}}</span>
                    <span class="cp field-main ml10" style="color: #1991FC">修改</span>
                </div>
                <div>
                    <span class="field-name">固定电话：</span>
                    <span class="field-main">{{form.telephone}}</span>
                </div>
            </div>
        </div>

        <div class="module bs_b pl24 pb20 pt20 pr24 mb50">
            <div class="title-box flex f_ai_c">
                <img class="title-icon" src="../../assets/images/supplier/approval.png" alt="">
                <div class="title-text">审批信息</div>
            </div>
            <div class="field-box">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="approveReason"
                show-word-limit
                >
                </el-input>
            </div>
        </div>
        <div class="zw"></div>
        <div class="btn-box fs14 flex f_jc_c f_ai_c">
            <div class="return cp"  @click="returnGo">返回</div>
            <div class="reject cp" v-if="form.status == 1" @click="reject">拒绝</div>
            <div class="pass cp"  v-if="form.status == 1" @click="pass">通过</div>
        </div>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: '',
    data(){
        return{
            dialogVisible: false,
            dialogImageUrl: '',
            userId: this.$route.query.userId,
            id: this.$route.query.id,
            form: {
            
            },
            approveReason: '' //审批原因
        }
    },
    
    mounted(){
        console.log(moment(this.form.companyCreateDate).format('YYYY-MM-DD'))
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.queryUniqueObject({userId: this.userId}).then(res => {
                if(res.respCode == "0000") {
                    this.form = res.data
                    this.form.companyCreateDate = moment(this.form.companyCreateDate).format('YYYY-MM-DD')
                    this.form.businessBeginTerm = moment(this.form.businessBeginTerm).format('YYYY-MM-DD')
                    this.form.businessEndTerm = moment(this.form.businessEndTerm).format('YYYY-MM-DD')
                    this.form.credentialsValidityBeginDate = moment(this.form.credentialsValidityBeginDate).format('YYYY-MM-DD')
                    this.form.credentialsValidityEndDate = moment(this.form.credentialsValidityEndDate).format('YYYY-MM-DD')
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        returnGo() {
            this.$router.push('supplierApproval');
        },
        reject() {
            if(this.approveReason == '' ) {
                this.$message.error('请输入审批原因');
                return
            }
            var data = {
                status: 2,
                approveReason:  this.approveReason,
                id: this.id,
                userId: this.userId
            }
            this.qa.doApproveById(data).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '已拒绝！',
                        type: 'success'
                    });
                    this.$router.push('supplierApproval');
                } else {
                    this.$message.error(res.msg);
                }
            })
        }, 
        dialogImageUrlClick(url) {
            this.dialogVisible = true
            this.dialogImageUrl = url
        },
        pass() {
            var data = {
                status: 3,
                approveReason:  this.approveReason,
                id: this.id,
                userId: this.userId
            }
            this.qa.doApproveById(data).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '已通过！',
                        type: 'success'
                    });
                    this.$router.push('supplierApproval');
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
    }
}
</script>

<style scoped>
    .supplierDetails {
        background: #F6F7F9;
    }
    .module {
        background: #fff;
        margin-bottom: 20px;
    }
    .logo {
        width: 75px;
        height: 75px;
    }
    .title-box {
        margin-bottom: 20px;
    }
    .title-icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        color: #1D1E1F;
        font-size: 16px;
    }
    .field-box {
        padding-left: 22px;
    }
    .field-name {
        font-size: 14px;
        color: #A4A4A5;
    }
    .field-main {
        font-size: 14px;
        color: #1D1E1F;
        margin-right: 50px;
    }
    .field-item {
        margin-bottom: 15px;
    }
    .field-img {
        width: 80px;
        height: 60px;
    }
    .field-line {
        width: 100%;
        height: 1px;
        background: #EEEEEE;
    }
    .zw {
        width: 100%;
        height: 44px;
    }
    .btn-box {
        position: fixed;
        bottom: 0;
        box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.06);
        width: 100%;
        height: 44px;
        background: #fff;
        z-index: 5;
        margin-left: -24px;
    }
    .btn-box div {
        width: 83px;
        text-align: center;
        height: 32px;
        line-height: 32px;
        box-sizing: border-box;    
    }
    .return {
        color: #A4A5A5;
        border: 1px solid #A4A5A5;
        background: #fff;
    }
    .reject {
        border: 1px solid #F53333;
        background: #F53333;
        margin: 0 15px;
        color: #fff;
    }
    .pass {
        border: 1px solid #1991FC;
        background: #1991FC;
        color: #fff;
    }
</style>